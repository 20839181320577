<template>
  <div class="catalog-mobile-filter">
    <div class="catalog-filter">
      <div class="mobile-filter-header">
        <div class="toggle-filter" @click="toggleFilter">
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.88111 7.99886L15.6089 2.28357C15.8597 2.03271 16.0007 1.69246 16.0007 1.33769C16.0007 0.98291 15.8597 0.642664 15.6089 0.391799C15.3581 0.140935 15.0179 0 14.6632 0C14.3084 0 13.9682 0.140935 13.7174 0.391799L8.00293 6.12041L2.28845 0.391799C2.03762 0.140935 1.69743 -2.64329e-09 1.3427 0C0.987973 2.64329e-09 0.647776 0.140935 0.396947 0.391799C0.146117 0.642664 0.00520296 0.98291 0.00520296 1.33769C0.00520295 1.69246 0.146117 2.03271 0.396947 2.28357L6.12474 7.99886L0.396947 13.7142C0.272096 13.838 0.173 13.9853 0.105374 14.1477C0.0377473 14.31 0.00292969 14.4842 0.00292969 14.66C0.00292969 14.8359 0.0377473 15.01 0.105374 15.1724C0.173 15.3347 0.272096 15.4821 0.396947 15.6059C0.520778 15.7308 0.668103 15.8299 0.830425 15.8975C0.992748 15.9652 1.16685 16 1.3427 16C1.51855 16 1.69265 15.9652 1.85497 15.8975C2.0173 15.8299 2.16462 15.7308 2.28845 15.6059L8.00293 9.87731L13.7174 15.6059C13.8412 15.7308 13.9886 15.8299 14.1509 15.8975C14.3132 15.9652 14.4873 16 14.6632 16C14.839 16 15.0131 15.9652 15.1754 15.8975C15.3378 15.8299 15.4851 15.7308 15.6089 15.6059C15.7338 15.4821 15.8329 15.3347 15.9005 15.1724C15.9681 15.01 16.0029 14.8359 16.0029 14.66C16.0029 14.4842 15.9681 14.31 15.9005 14.1477C15.8329 13.9853 15.7338 13.838 15.6089 13.7142L9.88111 7.99886Z" fill="black"/>
          </svg>
        </div>
        <span>Фильтры</span>
      </div>
      <div class="form-group sorting">
        <label class="label">Сортировка</label>
        <select v-model="sortingData" @change="select">
          <option value="" :disabled="true">Выберите сортировку</option>
          <option value="duration[desc]">Сначала длинные</option>
          <option value="duration[asc]">Сначала короткие</option>
          <option value="price[desc]">Сначала дорогие</option>
          <option value="price[asc]">Сначала дешевые</option>
        </select>
      </div>

      <div class="check-filter" style="margin-top: 0;">
        <label class="label">Класс обслуживания</label>

        <div class="checkboxes">
          <div class="checkbox-custom">
            <input
                type="checkbox"
                true-value="a"
                false-value=""
                v-model="data.filters.class">
            <label>Все классы</label>
          </div>

          <div class="checkbox-custom">
            <input
                type="checkbox"
                true-value="e"
                false-value=""
                v-model="data.filters.class">
            <label>Эконом класс</label>
          </div>

          <div class="checkbox-custom">
            <input
                type="checkbox"
                true-value="w"
                false-value=""
                v-model="data.filters.class">
            <label>Комфорт класс</label>
          </div>

          <div class="checkbox-custom">
            <input
                type="checkbox"
                true-value="b"
                false-value=""
                v-model="data.filters.class">
            <label>Бизнес класс</label>
          </div>

          <div class="checkbox-custom">
            <input
                type="checkbox"
                true-value="f"
                false-value=""
                v-model="data.filters.class">
            <label>Первый класс</label>
          </div>

        </div>
      </div>

      <div class="check-filter">
        <label class="label">Пересадки</label>

        <div class="checkboxes">
          <div class="checkbox-custom">
            <input
                type="checkbox"
                true-value="1"
                false-value="0"
                v-model="data.filters.direct_only">
            <label>Пересадки</label>
          </div>
        </div>
      </div>

      <div class="price-filter">
        <label class="label">По цене (руб.)</label>
        <div class="inputs" style="display: flex; justify-content: space-between;">
          <input type="text" v-model="data.filters.price.min"
                 style="height: 40px; margin-right: 3px;"
                 placeholder="Цена от">
          <input type="text" v-model="data.filters.price.max"
                 style="height: 40px; margin-left: 3px;"
                 placeholder="Цена до">
        </div>
      </div>

      <div class="filter-buttons">
        <button class="btn btn-canceled">Сбросить</button>
        <button class="btn btn-primary" @click="startFilter">Показать отели</button>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
export default {
  name: 'catalog-avia-mobile-filters',
  data: function () {
    return {
      data: {
        filters: {
          class: '',
          direct_only: 0,
          price: {
            min: 0,
            max: 5000000
          }
        },
        sorting: {
          duration: null,
          price: null
        }
      },
      sortingData: '',
    }
  },
  computed: {},
  async mounted() {
    const q = qs.parse(this.$route.query)

    if (q.filters) {
      if (q.filters.class) {
        this.data.filters.class = q.filters.class
      }
      if (q.filters.direct_only) {
        this.data.filters.direct_only = q.filters.direct_only
      }
      if (q.filters.price) {
        this.data.filters.price.min = q.filters.price.min ?? 0
        this.data.filters.price.max = q.filters.price.max ?? 5000000
      }
    }

    if (q.sorting) {
      this.data.sorting.duration = q.sorting.duration ?? null
      this.data.sorting.price = q.sorting.price ?? null
    }
  },
  methods: {
    select: function () {
      let str = this.sortingData.split('[')

      Object.keys(this.data.sorting).map((item) => {
        if(str[0] !== item) {
          this.data.sorting[item] = null
        }
      })

      this.data.sorting[str[0]] = str[1].replace(']', '')
    },
    toggleFilter: function () {
      this.$root.$emit('triggerAviaMobileFilter');
    },
    startFilter: function () {
      this.toggleFilter()
      this.$root.$emit('setAviaFilter', this.data.filters)
      this.$root.$emit('setAviaSorting', this.data.sorting)
    }
  }
}
</script>
