<template>
    <div class="ticket">
        <div class="logo">
            <skeleton-box
                    v-if="loading"
                    :width="'50px'"
                    height="50px"/>
            <slot
                    v-else
                    name="logo"
            />
        </div>

        <div class="timing">
            <div class="item">
                <div class="there">
                    <div class="from">
                        <skeleton-box
                            v-if="loading"
                            :width="'150px'"
                            height="90px"/>
                        <slot
                            v-else
                            name="from"
                        />
                    </div>

                    <div class="in-flight" v-if="$store.getters.width > 592">
                        <skeleton-box
                            v-if="loading"
                            :width="'100%'"
                            :margin="'0 10px 0 10px'"
                            height="15px"/>
                        <slot
                            v-else
                            name="flight"
                        />
                    </div>

                    <div class="to">
                        <skeleton-box
                            v-if="loading"
                            :width="'150px'"
                            :margin="'0 10px 0 0'"
                            height="90px"/>
                        <slot
                            v-else
                            name="to"
                        />
                    </div>
                </div>
            </div>

        </div>

        <div class="prices">
            <div class="baggage-info">
                <skeleton-box
                    v-if="loading"
                    :width="'150px'"
                    height="60px"/>
                <slot
                    v-else
                    name="baggage"
                />
            </div>
            <div class="price-and-select">
                <skeleton-box
                    v-if="loading"
                    :width="'150px'"
                    height="50px"/>
                <slot
                    v-else
                    name="price"
                />
            </div>
        </div>

    </div>
</template>

<script>
import SkeletonBox from "@/components/loaders/SkeletonBox";

export default {
    name: `avia-item`,
    components: {SkeletonBox},
    props: {
        loading: {
            default: false,
            type: Boolean,
        },
    },
    methods: {}
};
</script>