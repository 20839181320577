<template>
  <div class="progress">
    <p>{{ text }}</p>
    <div class="progress-bar"></div>
  </div>
</template>

<script>
export default {
  name: `ProgressBar`,
  props: {
    text: {
      default: 'Загружаем список...',
      type: String,
    },
  },
  computed: {},
};
</script>

<style scoped>

.progress .progress-bar {
  position: relative;
  display: inline-block;
  width: 0;
  height: 10px;
  border-radius: 4px;
  animation: load 8s infinite;
  overflow: hidden;
}

.progress .progress-bar:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite;
  content: '';
}

@keyframes load {
  0% {
    width: 0;
    background: #d0bdf4;
  }
  25% {
    width: 25%;
    background: #d0bdf4;
  }
  50% {
    width: 50%;
    background: #d0bdf4;
  }
  75% {
    width: 75%;
    background: #d0bdf4;
  }
  100% {
    width: 100%;
    background: #d0bdf4;
  }
}


@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
