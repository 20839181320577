<template>
    <div class="container">
        <div class="catalog" id="catalog">

            <!-- Поиск для декстоп -->

            <div class="catalog-search" v-if="this.$store.getters.width >= 1200">
                <div class="position-search">
                    <label class="label">Откуда</label>
                    <input type="text" placeholder="Откуда" v-model="data.from.text" @keyup="triggerSearchFrom">
                    <img
                            class="clear"
                            src="@/assets/images/input/clear-input.svg"
                            v-if="data.from.text.length > 0"
                            @click="data.from.text = ''; data.from.code = ''" alt=""/>
                    <input-loader :loading="fromLoading" :css-top="40" :css-right="25"/>
                    <auto-complete-avia :cities="autocomplete.cities" :type="'from'"
                                        v-if="this.$store.state.searchTriggerFrom"/>
                </div>
                <div class="position-search">
                    <label class="label">Куда</label>
                    <input type="text" placeholder="Куда" v-model="data.to.text" @keyup="triggerSearchTo">
                    <img
                            class="clear"
                            src="@/assets/images/input/clear-input.svg"
                            v-if="data.to.text.length > 0"
                            @click="data.to.text = ''; data.to.code = ''" alt=""/>
                    <input-loader :loading="toLoading" :css-top="40" :css-right="25"/>
                    <auto-complete-avia :cities="autocomplete.cities" :type="'to'"
                                        v-if="this.$store.state.searchTrigger"/>
                </div>
                <div class="date">
                    <label class="label">Дата вылета</label>
                    <date-picker2 v-model="data.date" placeholder="Дата вылета" :format="'DD-MM-YYYY'"
                                  :value-type="'YYYY-MM-DD'" :disabled-date="$core.disabledDate" :clearable="false"/>
                </div>
                <div class="date">
                    <label class="label">Обратно</label>
                    <date-picker2 v-model="data.returnDate" placeholder="Обратно" :format="'DD-MM-YYYY'"
                                  :value-type="'YYYY-MM-DD'" :disabled-date="$core.disabledDate"/>
                </div>
                <div class="form-group">
                    <label
                            class="label"
                            @click="$store.state.selectPlacement = !$store.state.selectPlacement">
                        Пассажиры
                    </label>
                    <input
                            type="text"
                            class="search-room"
                            v-model="selectedRoom"
                            :readonly="true"
                            @click="$store.state.selectPlacement = !$store.state.selectPlacement">
                    <transition name="fade">
                        <div class="select-placement" v-if="$store.state.selectPlacement">
                            <div class="placement-item">
                                <div class="placement-item-titles">
                                    <div class="placement-item-title">Взрослые</div>
                                    <div class="placement-item-sub-title">старше 17 лет</div>
                                </div>
                                <div class="placement-item-counter">
                                    <button class="btn" @click="updateCounter('adults', 0)" :disabled="data.adults < 2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                                            <path fill="currentColor" d="M3 7a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H3Z"></path>
                                        </svg>
                                    </button>
                                    <input class="counter" v-model="data.adults" :disabled="true"/>
                                    <button class="btn" @click="updateCounter('adults', 1)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                                            <path fill="currentColor"
                                                  d="M9 3a1 1 0 0 0-2 0v4H3a1 1 0 0 0 0 2h4v4a1 1 0 1 0 2 0V9h4a1 1 0 1 0 0-2H9V3Z"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div class="placement-item">
                                <div class="placement-item-titles">
                                    <div class="placement-item-title">Дети</div>
                                    <div class="placement-item-sub-title">от 0 до 17 лет</div>
                                </div>
                                <div class="placement-item-counter">
                                    <button class="btn" @click="updateCounter('children', 0)"
                                            :disabled="data.children < 1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                                            <path fill="currentColor" d="M3 7a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H3Z"></path>
                                        </svg>
                                    </button>
                                    <input class="counter" v-model="data.children" :disabled="true"/>
                                    <button class="btn" @click="updateCounter('children', 1)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                                            <path fill="currentColor"
                                                  d="M9 3a1 1 0 0 0-2 0v4H3a1 1 0 0 0 0 2h4v4a1 1 0 1 0 2 0V9h4a1 1 0 1 0 0-2H9V3Z"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div class="placement-item child-age" v-if="data.children > 0">
                                <multiselect
                                        v-for="index in data.children"
                                        :key="index"
                                        v-model="childrenAges[index - 1]"
                                        track-by="name"
                                        label="name"
                                        :options="options"
                                        :searchable="false"
                                        :close-on-select="true"
                                        :show-labels="false"
                                        placeholder="Укажите возраст"
                                        :openDirection="'below'"
                                ></multiselect>
                            </div>
                        </div>
                    </transition>
                </div>
                <button class="btn btn-primary search-hotel" @click="getSearch(true)">Поиск</button>
            </div>

            <!-- Поиск для декстоп END -->

            <!-- Поиск для мобилы -->

            <div class="catalog-search-mobile"
                 v-else-if="this.$store.getters.width < 1200 && this.$store.getters.scroll + 1000 < this.catalogHeight">
                <div class="toggle-top-catalog" @click="$core.scrollToElement('body')">
                    <div class="circle-top-catalog">
                        <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M0.355112 6.00899C-0.118458 6.48255 -0.118458 7.25036 0.355112 7.72393C0.828681 8.19749 1.59649 8.19749 2.07006 7.72393L6.86671 2.92729L11.661 7.72156C12.1333 8.19387 12.899 8.19387 13.3714 7.72156C13.8437 7.24925 13.8437 6.48348 13.3714 6.01117L7.71443 0.354236C7.24212 -0.118075 6.47635 -0.118075 6.00405 0.354236C5.94458 0.413703 5.8926 0.477821 5.84811 0.54542C5.78694 0.587274 5.72876 0.635357 5.67445 0.689668L0.355112 6.00899Z"
                                  fill="black"/>
                        </svg>
                    </div>
                </div>
                <div class="left" @click="$root.$emit('triggerAviaSearchMobile')">
                    <div class="search-icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                    d="M8.55295 17.1059C10.4506 17.1055 12.2936 16.4702 13.7884 15.3012L18.4883 20.0011L20 18.4893L15.3002 13.7895C16.4698 12.2945 17.1054 10.4511 17.1059 8.55295C17.1059 3.83707 13.2688 0 8.55295 0C3.83707 0 0 3.83707 0 8.55295C0 13.2688 3.83707 17.1059 8.55295 17.1059ZM8.55295 2.13824C12.0907 2.13824 14.9677 5.01523 14.9677 8.55295C14.9677 12.0907 12.0907 14.9677 8.55295 14.9677C5.01523 14.9677 2.13824 12.0907 2.13824 8.55295C2.13824 5.01523 5.01523 2.13824 8.55295 2.13824Z"
                                    fill="black"/>
                        </svg>
                    </div>
                    <div class="filter-info">
                        <span>{{ data.from.text }} - {{ data.to.text }}</span>
                        <span>взрослых - {{ data.adults }}, детей - {{ data.children }}</span>
                    </div>
                </div>
                <div class="right">
                    <div class="filter-toggle" @click="toggleFilter">
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                    d="M16.9998 3.55556C16.9998 4.95527 15.8475 6.11111 14.3998 6.11111C12.9521 6.11111 11.7998 4.95527 11.7998 3.55556C11.7998 2.15584 12.9521 1 14.3998 1C15.8475 1 16.9998 2.15584 16.9998 3.55556Z"
                                    stroke="white" stroke-width="2"/>
                            <line x1="10.7998" y1="3.66699" x2="-0.000195503" y2="3.66699" stroke="white"
                                  stroke-width="2"/>
                            <path
                                    d="M6.2 12.4442C6.2 13.8439 5.04769 14.9998 3.6 14.9998C2.15231 14.9998 1 13.8439 1 12.4442C1 11.0445 2.15231 9.88867 3.6 9.88867C5.04769 9.88867 6.2 11.0445 6.2 12.4442Z"
                                    stroke="white" stroke-width="2"/>
                            <line x1="18" y1="12.5557" x2="7.2" y2="12.5557" stroke="white" stroke-width="2"/>
                        </svg>
                    </div>
                </div>
            </div>

            <!-- Поиск для мобилы END -->

            <div class="catalog-main">

                <div class="catalog-filter" v-if="this.$store.getters.width >= 1200">

                    <div class="check-filter" style="margin-top: 0;">
                        <label class="label">Класс обслуживания</label>

                        <div class="checkboxes">
                            <div class="checkbox-custom">
                                <input
                                        type="checkbox"
                                        true-value="a"
                                        false-value=""
                                        v-model="data.filters.class">
                                <label>Все классы</label>
                            </div>

                            <div class="checkbox-custom">
                                <input
                                        type="checkbox"
                                        true-value="e"
                                        false-value=""
                                        v-model="data.filters.class">
                                <label>Эконом класс</label>
                            </div>

                            <div class="checkbox-custom">
                                <input
                                        type="checkbox"
                                        true-value="w"
                                        false-value=""
                                        v-model="data.filters.class">
                                <label>Комфорт класс</label>
                            </div>

                            <div class="checkbox-custom">
                                <input
                                        type="checkbox"
                                        true-value="b"
                                        false-value=""
                                        v-model="data.filters.class">
                                <label>Бизнес класс</label>
                            </div>

                            <div class="checkbox-custom">
                                <input
                                        type="checkbox"
                                        true-value="f"
                                        false-value=""
                                        v-model="data.filters.class">
                                <label>Первый класс</label>
                            </div>

                        </div>
                    </div>

                    <div class="check-filter">
                        <label class="label">Пересадки</label>

                        <div class="checkboxes">
                            <div class="checkbox-custom">
                                <input
                                        type="checkbox"
                                        true-value="1"
                                        false-value="0"
                                        v-model="data.filters.direct_only">
                                <label>Пересадки</label>
                            </div>
                        </div>
                    </div>

                    <div class="price-filter">
                        <label class="label">По цене (руб.)</label>
                        <div class="inputs" style="display: flex; justify-content: space-between;">
                            <input type="text" v-model="data.filters.price.min"
                                   style="height: 40px; margin-right: 3px;"
                                   placeholder="Цена от">
                            <input type="text" v-model="data.filters.price.max"
                                   style="height: 40px; margin-left: 3px;"
                                   placeholder="Цена до">
                        </div>
                    </div>

                    <div class="filter-buttons">
                        <button class="btn btn-canceled" @click="clearFilter" :disabled="loading">Сбросить</button>
                        <button class="btn btn-primary" @click="getSearch" :disabled="loading">Показать билеты</button>
                    </div>
                </div>

                <!-- Фильтры для мобилы -->

                <transition enter-active-class="animate__animated animate__slideInUp"
                            leave-active-class="animate__animated animate__slideOutDown">
                    <catalog-avia-mobile-filters v-show="mobileFilter"/>
                </transition>

                <!-- Фильтры для мобилы END -->

                <div class="catalog-items">

                    <div class="catalog-items-sorting" v-if="$store.getters.width >= 1200">
                        <span>Сортировать по</span>
                        <div class="sorting-items">
                            <div class="sorting-item"
                                 :class="[data.sorting.price ? 'active ' + data.sorting.price : '']"
                                 @click="switchSorting('price')">
                                цене
                            </div>
                            <div class="sorting-item"
                                 :class="[data.sorting.duration ? 'active ' + data.sorting.duration : '']"
                                 @click="switchSorting('duration')">время полета
                            </div>
                        </div>
                    </div>

                    <div class="items-info">

                        <template v-if="loading">
                            <progress-bar :text="'Ищем билеты по вашему запросу...'"/>
                        </template>
                        <template v-else>
                            <p style="font-weight: bold; font-size: 20px;">{{ itemsTotal }}
                                {{
                                this.$core.declination(items.length, ['предложение', 'предложения', 'предложений'])
                                }}
                            </p>
                        </template>
                    </div>

                    <div class="flight-tickets" v-if="itemsTotal > 0">

                        <template v-if="loading">
                            <avia-item v-for="n in 6"
                                       :key="n" loading/>
                        </template>

                        <template v-else>
                            <div class="ticket" v-for="(item, index) in items" :key="index">
                                <div class="logo">
                                    <template v-if="item.segments.length <= 1">
                  <span>
                    <img :src="item.provider_logo" v-if="item.provider_logo"/>
                    {{ item.segments[0].provider.supplier.title }}
                  </span>
                                    </template>

                                    <template v-else-if="item.segments.length >= 2">
                                        <template
                                            v-if="item.segments[0].provider.supplier.id == item.segments[1].provider.supplier.id">
                    <span>
                      <img :src="item.provider_logo" v-if="item.provider_logo"/>
                      {{ item.segments[0].provider.supplier.title }}
                    </span>
                                        </template>
                                        <template v-else>
                    <span>
                      <img :src="item.provider_logo" v-if="item.provider_logo"/>
                      {{ item.segments[0].provider.supplier.title }}
                    </span>
                                            <span>{{ item.segments[1].provider.supplier.title }}</span>
                                        </template>
                                    </template>


                                </div>

                                <div class="timing">
                                    <div class="item" v-for="(segment, index) in item.segments" :key="index">

                                        <div class="there" v-if="segment.direction === 0">
                                            <div class="from">
                                                <div class="datetime">
                                                    <time>{{ segment.dep.time }}</time>
                                                    <span>{{
                                                            $moment.unix(segment.dep.ts).format('DD MMM')
                                                        }}</span>
                                                </div>
                                                <div class="airport">
                                                    {{ segment.dep.airport.title }}
                                                    ({{ segment.dep.airport.code }})
                                                </div>
                                            </div>
                                            <div class="in-flight" v-if="$store.getters.width > 592">
                                                <div class="line-flight"></div>
                                                <span>{{ segment.duration.flight.hour }}ч {{
                                                        segment.duration.flight.minute
                                                    }} мин</span>
                                                <div class="line-flight"></div>
                                            </div>
                                            <div class="to">
                                                <div class="datetime">
                                                    <time>{{ segment.arr.time }}</time>
                                                    <span>{{
                                                            $moment.unix(segment.arr.ts).format('DD MMM')
                                                        }}</span>
                                                </div>
                                                <div class="airport">
                                                    {{ segment.arr.airport.title }}
                                                    ({{ segment.arr.airport.code }})
                                                </div>
                                            </div>
                                        </div>

                                        <div class="back" v-else>
                                            <div class="from">
                                                <div class="datetime">
                                                    <time>{{ segment.dep.time }}</time>
                                                    <span>{{
                                                            $moment.unix(segment.dep.ts).format('DD MMM')
                                                        }}</span>
                                                </div>
                                                <div class="airport">
                                                    {{ segment.dep.airport.title }}
                                                    ({{ segment.dep.airport.code }})
                                                </div>
                                            </div>
                                            <div class="in-flight">
                                                <div class="line-flight"></div>
                                                <span>{{ segment.duration.flight.hour }}ч {{
                                                        segment.duration.flight.minute
                                                    }} мин</span>
                                                <div class="line-flight"></div>
                                            </div>
                                            <div class="to">
                                                <div class="datetime">
                                                    <time>{{ segment.arr.time }}</time>
                                                    <span>{{
                                                            $moment.unix(segment.arr.ts).format('DD MMM')
                                                        }}</span>
                                                </div>
                                                <div class="airport">
                                                    {{ segment.arr.airport.title }}
                                                    ({{ segment.arr.airport.code }})
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <div class="prices">
                                    <div class="baggage-info">
                                        <div class="baggage good" v-if="item.segments[0].cbaggage.piece > 0">
                                            <svg width="26" height="20" viewBox="0 0 26 20" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_1758_70789)">
                                                    <path
                                                        d="M8.18111 3.36377L8.17211 3.49797H8.30662H9.24788H9.36304L9.37317 3.38326C9.50669 1.87247 10.818 0.996113 12.4482 0.996113C14.0777 0.996113 15.3909 1.87235 15.5469 3.38509L15.5585 3.49797H15.672H16.6132H16.7493L16.7386 3.36239C16.6533 2.27137 16.1399 1.42672 15.364 0.857434C14.5908 0.290076 13.563 0 12.4482 0C11.3335 0 10.3054 0.290051 9.53507 0.857573C8.76193 1.42713 8.25425 2.27229 8.18111 3.36377ZM18.7421 5.09343H6.41509V19.9986H18.7421V5.09343ZM20.2516 19.9971C22.9722 19.9303 25.1572 17.7039 25.1572 14.9672V10.1249C25.1572 7.38814 22.9722 5.16172 20.2516 5.09497V19.9971ZM1.25957e-05 10.1249C1.25957e-05 7.38814 2.18501 5.16174 4.90566 5.09497V19.9971C2.18499 19.9303 0 17.7039 0 14.9672L1.25957e-05 10.1249Z"
                                                        fill="#43B02A"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1758_70789">
                                                        <rect width="25.1572" height="20" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            Ручная кладь
                                            <template v-if="item.segments[0].cbaggage.weight">{{
                                                    item.segments[0].cbaggage.weight
                                                }}кг
                                            </template>
                                        </div>
                                        <div class="baggage good" v-if="item.segments[0].baggage.piece > 0">
                                            <svg width="15" height="20" viewBox="0 0 15 20" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.625 2.1875C5.625 2.01562 5.76562 1.875 5.9375 1.875H9.0625C9.23438 1.875 9.375 2.01562 9.375 2.1875V5H5.625V2.1875ZM12.5 5H11.25V2.1875C11.25 0.980469 10.2695 0 9.0625 0H5.9375C4.73047 0 3.75 0.980469 3.75 2.1875V5H2.5C1.12109 5 0 6.12109 0 7.5V16.25C0 17.6289 1.12109 18.75 2.5 18.75C2.5 19.4414 3.05859 20 3.75 20C4.44141 20 5 19.4414 5 18.75H10C10 19.4414 10.5586 20 11.25 20C11.9414 20 12.5 19.4414 12.5 18.75C13.8789 18.75 15 17.6289 15 16.25V7.5C15 6.12109 13.8789 5 12.5 5ZM4.375 8.75H10.625C10.9688 8.75 11.25 9.03125 11.25 9.375C11.25 9.71875 10.9688 10 10.625 10H4.375C4.03125 10 3.75 9.71875 3.75 9.375C3.75 9.03125 4.03125 8.75 4.375 8.75ZM4.375 13.75H10.625C10.9688 13.75 11.25 14.0312 11.25 14.375C11.25 14.7188 10.9688 15 10.625 15H4.375C4.03125 15 3.75 14.7188 3.75 14.375C3.75 14.0312 4.03125 13.75 4.375 13.75Z"
                                                    fill="black"/>
                                            </svg>
                                            С багажом
                                        </div>
                                        <div class="baggage" v-else>
                                            <svg width="15" height="20" viewBox="0 0 15 20" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.625 2.1875C5.625 2.01562 5.76562 1.875 5.9375 1.875H9.0625C9.23438 1.875 9.375 2.01562 9.375 2.1875V5H5.625V2.1875ZM12.5 5H11.25V2.1875C11.25 0.980469 10.2695 0 9.0625 0H5.9375C4.73047 0 3.75 0.980469 3.75 2.1875V5H2.5C1.12109 5 0 6.12109 0 7.5V16.25C0 17.6289 1.12109 18.75 2.5 18.75C2.5 19.4414 3.05859 20 3.75 20C4.44141 20 5 19.4414 5 18.75H10C10 19.4414 10.5586 20 11.25 20C11.9414 20 12.5 19.4414 12.5 18.75C13.8789 18.75 15 17.6289 15 16.25V7.5C15 6.12109 13.8789 5 12.5 5ZM4.375 8.75H10.625C10.9688 8.75 11.25 9.03125 11.25 9.375C11.25 9.71875 10.9688 10 10.625 10H4.375C4.03125 10 3.75 9.71875 3.75 9.375C3.75 9.03125 4.03125 8.75 4.375 8.75ZM4.375 13.75H10.625C10.9688 13.75 11.25 14.0312 11.25 14.375C11.25 14.7188 10.9688 15 10.625 15H4.375C4.03125 15 3.75 14.7188 3.75 14.375C3.75 14.0312 4.03125 13.75 4.375 13.75Z"
                                                    fill="black"/>
                                            </svg>
                                            Без багажа
                                        </div>
                                    </div>
                                    <div class="price-and-select">
                                        <div class="price">{{ item.amount }} ₽</div>
                                        <a class="btn btn-info" @click="startConfirmation(item)">Выбрать тариф</a>
                                    </div>
                                </div>
                            </div>
                        </template>


                    </div>

                    <div class="catalog-items-empty" v-else-if="itemsTotal === 0 && !loading">
                        По вашему запросу авиа билеты не найдены, попробуйте изменить фильтр поиска
                    </div>

                    <button-load
                            class="btn btn-white"
                            :data-style="'zoom-in'"
                            :loading="pLoading"
                            v-if="itemsTotal > data.limit"
                            @click="loadMore">Показать ещё
                    </button-load>
                </div>

            </div>

        </div>

        <transition enter-active-class="animate__animated animate__slideInUp"
                    leave-active-class="animate__animated animate__slideOutDown">
            <avia-mobile-search v-if="this.$store.state.avia.searchMobile"/>
        </transition>

    </div>
</template>

<script>
import DatePicker2 from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import qs from "qs";
import AutoCompete from "@/components/AutoCompete/hotel/AutoCompete.vue";
import InputLoader from "@/components/loaders/input/loader.vue";
import AutoCompleteAvia from "@/components/AutoCompete/avia/AutoCompete.vue";
import ProgressBar from "@/components/loaders/ProgressBar.vue";
import AviaMobileSearch from "@/components/mobile/avia/search.vue";
import CatalogAviaMobileFilters from "../../components/mobile/avia/filter";
import AviaItem from "@/components/avia/avia-item.vue";

export default {
    name: 'AviaCatalog',
    components: {
        AviaItem,
        CatalogAviaMobileFilters,
        AviaMobileSearch, ProgressBar, AutoCompleteAvia, InputLoader, AutoCompete, DatePicker2
    },
    data: function () {
        return {
            items: [],
            itemsTotal: 1,
            hash: '',
            fromLoading: false,
            toLoading: false,
            loading: true,
            mobileFilter: false,
            pLoading: false,
            data: {
                from: {
                    text: '',
                    code: '',
                },
                to: {
                    text: '',
                    code: '',
                },
                date: this.$moment().add(1, 'days').format('Y-MM-DD'),
                returnDate: null,
                adults: 1,
                children: 0,
                ages: [],
                limit: 12,
                offset: 0,
                sorting: {
                    duration: null,
                    price: null
                },
                filters: {
                    class: '',
                    direct_only: 0,
                    price: {
                        min: 0,
                        max: 5000000
                    }
                },
            },
            autocomplete: {
                cities: [],
            },
            childrenAges: [],
            options: [
                {name: 'До года', code: '0'},
                {name: '1 год', code: '1'},
                {name: '2 года', code: '2'},
                {name: '3 года', code: '3'},
                {name: '4 года', code: '4'},
                {name: '5 лет', code: '5'},
                {name: '6 лет', code: '6'},
                {name: '7 лет', code: '7'},
                {name: '8 лет', code: '8'},
                {name: '9 лет', code: '9'},
                {name: '10 лет', code: '10'},
                {name: '11 лет', code: '11'},
                {name: '12 лет', code: '12'},
                {name: '13 лет', code: '13'},
                {name: '14 лет', code: '14'},
                {name: '15 лет', code: '15'},
                {name: '16 лет', code: '16'},
                {name: '17 лет', code: '17'}
            ],
            timer: null,
            catalogHeight: 0,
        }
    },
    watch: {
        childrenAges(newValue) {
            this.data.ages = []
            Object.keys(newValue).forEach((index) => {
                this.data.ages.push(this.childrenAges[index].code)
            })
        }
    },
    async mounted() {
        const q = qs.parse(this.$route.query)

        this.data.from.text = q.tFrom ?? 'Москва (MOW)'
        this.data.to.text = q.tTo ?? 'Санкт-Петербург (LED)'
        this.data.from.code = q.from ?? 'MOW'
        this.data.to.code = q.to ?? 'LED'
        this.data.date = q.date ?? this.$moment().add(1, 'days').format('Y-MM-DD')
        this.data.returnDate = q.returnDate
        this.data.adults = q.adults ?? 1

        if (q.children) {
            this.data.children = q.children.length ?? 0
            this.data.ages = q.children ?? []
            this.setAges()
        }

        if (q.filters) {
            if (q.filters.class) {
                this.data.filters.class = q.filters.class
            }
            if (q.filters.direct_only) {
                this.data.filters.direct_only = q.filters.direct_only
            }
            if (q.filters.price) {
                this.data.filters.price.min = q.filters.price.min ?? 0
                this.data.filters.price.max = q.filters.price.max ?? 5000000
            }
        }

        if (q.sorting) {
            this.data.sorting.duration = q.sorting.duration ?? null
            this.data.sorting.price = q.sorting.price ?? null
        }

        window.addEventListener('scroll', () => {
            let catalog = document.getElementById('catalog')

            if (catalog) {
                this.catalogHeight = document.getElementById('catalog').clientHeight
            }
        });

        this.$root.$on('autocomplete', (val) => {
            this.setAutoComplete(val)
        });

        this.$root.$on('startAviaSearch', (val) => {
            this.data.from.code = val.from.code
            this.data.from.text = val.from.text
            this.data.to.code = val.to.code
            this.data.to.text = val.to.text
            this.data.date = val.date
            this.data.returnDate = val.returnDate
            this.data.adults = val.adults
            this.data.children = val.ages.length
            this.data.ages = val.ages
            this.getSearch()
        });

        this.$root.$on('triggerAviaMobileFilter', () => {
            this.toggleFilter()
        });

        this.$root.$on('setAviaFilter', (val) => {
            this.data.filters = val
        });

        this.$root.$on('setAviaSorting', (val) => {
            this.data.sorting = val
            this.getSearch()
        });

        this.catalogHeight = document.getElementById('catalog').clientHeight + 150;
        this.getSearch()
    },
    computed: {
        selectedRoom: function () {
            let children = '';
            if (this.data.children > 0) {
                children = ', ' + this.data.children + ' ' + this.$core.declination(this.data.children, ['ребенок', 'ребенка', 'детей'])
            }
            return this.data.adults + ' '
                + this.$core.declination(this.data.adults, ['взрослый', 'взрослых', 'взрослых'])
                + children
        },
        dateRange: function () {
            let countClient = this.data.adults + this.data.children;
            return 'c ' + this.$moment(this.data.date[0]).format('DD.MM') + ' по '
                + this.$moment(this.data.date[1]).format('DD.MM') + ', '
                + ' для ' + countClient + ' '
                + this.$core.declination(countClient, ['ребенок', 'ребенка', 'участников']);
        },
    },
    methods: {
        setAges: function () {
            if (this.data.ages.length > 0) {
                this.data.ages.forEach((index) => {
                    this.childrenAges.push(this.options[index])
                })
            }
        },
        setAutoComplete: function (val) {
            this.data[val.type].text = val.name + ' (' + val.code + ')'
            this.data[val.type].code = val.code
        },
        updateCounter: function (variable, type) {
            if (type === 0 && variable === 'adults' && this.data[variable] > 1) {
                this.data[variable]--;
            } else if (type === 0 && variable === 'children' && this.data[variable] >= 1) {
                this.data[variable]--;
                this.childrenAges.pop();
            } else if (variable === 'children' && this.data[variable] < 4) {
                this.data[variable]++;
            } else if (variable !== 'children' && this.data[variable] < 6) {
                this.data[variable]++;
            }
        },
        filterActive: function (val) {
            this.data.filter[val] = !this.data.filter[val];
        },
        onWaypoint({going}) {
            if (going === this.$waypointMap.GOING_IN) {
                // this.data.limit += 12;
                // this.getSearch();
            }
        },
        triggerPlacement: function () {
            this.$store.state.selectPlacement = !this.$store.state.selectPlacement;
        },
        switchSorting: function (val) {
            if (!this.loading) {
                Object.keys(this.data.sorting).map((item) => {
                    if (val !== item) {
                        this.data.sorting[item] = null
                    }
                })

                if (this.data.sorting[val] === null) {
                    this.data.sorting[val] = 'desc';
                } else if (this.data.sorting[val] === 'desc') {
                    this.data.sorting[val] = 'asc';
                } else {
                    this.data.sorting[val] = null;
                }
                this.getSearch();
            }
        },
        toggleFilter: function () {
            this.mobileFilter = !this.mobileFilter;
        },
        clearFilter: function () {
            this.data.filters.class = ''
            this.data.filters.direct_only = 0
            this.data.filters.price.min = 0
            this.data.filters.price.max = 5000000
            this.getSearch()
        },
        loadMore: function () {
            this.data.limit += 12;
            this.pLoading = !this.pLoading
            this.getSearch(false)
        },
        // запросы на сервер

        // помощь в поиске аэропортом

        triggerSearchFrom: function () {
            if (!this.timer) {
                if (this.data.from.text.length > 1) {
                    this.$store.state.searchTrigger = false
                    this.fromLoading = true
                    this.timer = setTimeout(() => {
                        this.$store.dispatch('getAutoCompleteAirport', {
                            query: this.data.from.text
                        }).then((res) => {
                            this.autocomplete.cities = res.data.data.cities
                            this.$store.state.searchTriggerFrom = true
                            this.timer = null
                            this.fromLoading = false
                        }).catch(() => {
                            this.$toast.error('Ошибка... Повторите поиск')
                            this.timer = null
                            this.fromLoading = false
                            this.$store.state.searchTriggerFrom = false
                        })
                    }, 500)
                }
            }
        },
        triggerSearchTo: function () {
            if (!this.timer) {
                if (this.data.to.text.length > 1) {
                    this.$store.state.searchTriggerFrom = false
                    this.toLoading = true
                    this.timer = setTimeout(() => {
                        this.$store.dispatch('getAutoCompleteAirport', {
                            query: this.data.to.text
                        }).then((res) => {
                            this.autocomplete.cities = res.data.data.cities
                            this.$store.state.searchTrigger = true
                            this.timer = null
                            this.toLoading = false
                        }).catch(() => {
                            this.$toast.error('Ошибка... Повторите поиск')
                            this.timer = null
                            this.toLoading = false
                            this.$store.state.searchTrigger = false
                        })
                    }, 500)
                }
            }
        },
        startConfirmation: function (item) {
            this.$router.push({
                name: 'avia-confirmation',
                params: {
                    from: this.data.from,
                    to: this.data.to,
                    date: this.data.date,
                    returnDate: this.data.returnDate,
                    adults: this.data.adults,
                    children: this.data.ages,
                    item: item,
                },
            });
        },
        getSearch: function (load = true) {
            if (this.data.from.code.length <= 0 || this.data.to.code.length <= 0) {
                this.$toast.error('Выберите откуда и куда полетите')
                return false
            }

            this.loading = load;

            let filters = {
                class: this.data.filters.class,
                direct_only: this.data.filters.direct_only,
                price: this.data.filters.price
            }

            let data = {
                tFrom: this.data.from.text,
                from: this.data.from.code,
                to: this.data.to.code,
                tTo: this.data.to.text,
                date: this.data.date,
                adults: this.data.adults,
                children: this.data.ages,
                limit: this.data.limit,
                offset: this.data.offset,
                filters: _.omitBy(filters, _.isEmpty),
                sorting: _.omitBy(this.data.sorting, _.isEmpty)
            }

            if (this.data.returnDate) {
                data = Object.assign({returnDate: this.data.returnDate}, data)
            }

            this.$store.dispatch('getVariantsByAirport', data).then((res) => {
                this.items = res.data.data.flights
                this.itemsTotal = res.data.data.total_count

                if (this.items && this.items.length > 0) {
                    this.data.filters.price.min = data.filters.price.min = parseFloat(res.data.data.range_prices.min)
                    this.data.filters.price.max = data.filters.price.max = parseFloat(res.data.data.range_prices.max)
                }

                if (res.data.data.hash_search !== this.hash) {
                    this.hash = res.data.data.hash_search
                    this.data.limit = 12
                }

                window.history.replaceState(
                    {},
                    null,
                    '/avia?' + qs.stringify(data), {
                        arrayFormat: 'brackets'
                    })

                this.pLoading = false
                this.loading = false
            }).catch(() => {
                this.$toast.error('Произошла неожидонная ошибка, перезагрузите страницу')
            })
        }
    }
}
</script>

<style scoped>
.search-result {
    top: 75px;
}

.from-search, .to-search {
    width: 100%;
    margin-right: 25px;
    position: relative;
}

.from-search input, .to-search input {
    background: var(--white);
    border: 1px solid #96A3AE;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #001A34;
    height: 45px;
    text-indent: 10px;
}

.catalog .catalog-search .date .mx-datepicker-range {
    max-width: 320px;
}

.flight-tickets {
    margin-top: 10px;
}

.flight-tickets .ticket {
    background: #FFFFFF;
    border: none;
    box-shadow: none;
}

.search-result .result-list .result-list-item:first-child {
    margin-top: 0;
}

</style>
